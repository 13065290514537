<template>
  <div>
    <div class="mb-2">
      <a-collapse accordion>
        <a-collapse-panel key="1" header="Description">
          <div class="d-flex justify-content-flex-start align-items-center">
            <ul>
              <h4>Attendance :</h4>
              <li>AVG = Average Presences</li>
              <li>S = Sick</li>
              <li>P = Permit</li>
              <li>A = Absent</li>
              <li>
                <span
                  class="kit__utils__donut kit__utils__donut--not-absent-yet"
                ></span>
                = N/A
              </li>
              <li>
                <span
                  class="kit__utils__donut kit__utils__donut--present"
                ></span>
                = Present
              </li>
              <li>
                <span
                  class="kit__utils__donut kit__utils__donut--absent"
                ></span>
                = Absent
              </li>
              <li>
                <span class="kit__utils__donut kit__utils__donut--sick"></span>
                = Sick
              </li>
              <li>
                <span class="kit__utils__donut kit__utils__donut--leave"></span>
                = Permit
              </li>
            </ul>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loadingTable"
      :scroll="{ x: 1000, y: 500 }"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      size="small"
      bordered
      class="table-attendance"
    >
      <template slot="AttendanceStatus" slot-scope="text">
        <span
          v-if="text === 'default'"
          class="kit__utils__donut kit__utils__donut--not-absent-yet"
        ></span>
        <span
          v-if="text === 'present'"
          class="kit__utils__donut kit__utils__donut--present"
        ></span>
        <span
          v-if="text === 'absent'"
          class="kit__utils__donut kit__utils__donut--absent"
        ></span>
        <span
          v-if="text === 'sick'"
          class="kit__utils__donut kit__utils__donut--sick"
        ></span>
        <span
          v-if="text === 'excused'"
          class="kit__utils__donut kit__utils__donut--leave"
        ></span>
        <span v-if="!text">-</span>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    dataClass: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [],
      dataTable: [],
      loadingTable: false,
      idWaliKelas: null,
      isCurriculum: false,
    }
  },
  methods: {
    fetchDataTableAttendance() {
      this.loadingTable = true
      const idKelas = this.dataClass.idKelas
      this.$store.dispatch('homeroom/FETCH_HOMEROOM_DAILY_ATTENDANCE', { idKelas })
        .then(res => {
          this.loadingTable = false

          this.columns = res.dataColumn.map(column => {
            if (column.children) {
              column.children.forEach(element => {
                const tanggal = (
                  <a-tooltip>
                    <template slot="title">
                      {element.tooltip}
                    </template>
                    <a-button size="small" type={element.color}>{element.title}</a-button>
                  </a-tooltip>
                )
                element.title = tanggal
              })
            }
            return {
              ...column,
            }
          })

          this.dataTable = res.dataTable
        })
    },
  },
  mounted() {
    if (this.$route.query.isCurriculum) {
      this.isCurriculum = true
      this.idWaliKelas = this.$route.query.id_walikelas
    } else {
      this.idWaliKelas = this.user.id
    }
    this.fetchDataTableAttendance()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
.table-attendance {
  p.ant-empty-description {
    color: #000000;
  }
}
</style>
